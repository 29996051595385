import React from 'react';
import './features.css';

const Features=() => {
  return (
    <section id="testimonial" className="testimonial section">
    
      <div className="container section-title" data-aos="fade-up">
        <h2>Features You'll Enjoy</h2>
        <p>
        Say good-bye to the anxiety associated with vacation preparation and hello to a world of customized recommendations, simplified plans, and stress-free eating experiences.
        </p>
      </div>
      

      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item position-relative">
              <div className="icon" style={{ display: 'flex', alignItems: 'center' }}>
                <img src="./assets/img/features/1.svg" alt="Sarah's Testimonial" style={{ width: '160%' }} />
              
              <h3 style={{ margin: 0 }}>Travel Guidance</h3>
              </div>
              <p>
              Get insider secrets and time-saving techniques from experienced travelers. To make your trip unforgettable, our membership recommends their best locations and events.
              </p>
            </div>
          </div>
          

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div className="service-item position-relative">
            <div className="icon" style={{ display: 'flex', alignItems: 'center' }}>
                <img src="./assets/img/features/2.svg" alt="Sarah's Testimonial" style={{ width: '160%' }} />
              
              <h3 style={{ margin: 0 }}>Effective Planning</h3>
              </div>
              <p>
              Look for the best offers on flights, hotels, and activities. To help you save time and money, our AI analyzes expenses and responses.
              </p>
            </div>
          </div>
         

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div className="service-item position-relative">
            <div className="icon" style={{ display: 'flex', alignItems: 'center' }}>
                <img src="./assets/img/features/3.svg" alt="Sarah's Testimonial" style={{ width: '160%' }} />
              
              <h3 style={{ margin: 0 }}>Personalized Recommendations</h3>
              </div>
              <p>
              Find travel spots based on your preferences. We have recommendations particularly for you, regardless of your interests in culture, adventure, or relaxation.
              </p>
            </div>
          </div>
        
        </div>
      </div>
    </section>
  );
}
export default Features;
