import Nav from "../components/header";
import Hero  from "../components/hero";


import Partners from "../components/partners";

import Features from "../components/features";

import BlogSection from "../components/bloglist";
import Contact from "../components/contact";
import Footer from "../components/footer";
import FeaturesSection from "../components/featuredetails";
import Testimonial17 from "../components/testimonial";


import MoreFeatures from "../components/endfeaturesection";

export default function Home(){
return(
<>

<Nav/>
<Hero/>
<FeaturesSection/>
<Features/>
<Testimonial17/>

<BlogSection/>
<MoreFeatures/>
{/* <IconBoxSection/>
<Services/>

<AboutUs/>

<Pricing/> */}
<Partners/>
<Contact/>

<Footer/>






</>
);


}