import React from 'react';
import Logo from '../assets/img/rixsterpedia.svg';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import './footer.css';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
      navigate(path);
    };
    return (
        <footer id="footer" className="footer accent-background">

            <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-6 col-md-12 footer-about">
                        <a href="/" className="logo d-flex align-items-center">
                        <img src={Logo} alt="Company Logo" className="" />
                        </a>
                        <p>Use RixsterPedia to make your upcoming travel an enjoyable experience without any stress. Take advantage of easy group planning, intelligent route optimisation, and customized plans for a smooth travel experience.</p>
                        <div className="d-flex mt-4">
                            <a href="https://x.com/RixsterPedia" target='_blank'> <img src='/assets/img/social/x.svg' alt="X" className="" width="50%"/></a>
                            <a href="https://web.facebook.com/RixsterPedia/ " target='_blank'><img src='/assets/img/social/fb.svg' alt="fb" className="" width="50%"/></a>
                            <a href="https://www.youtube.com/@RixsterPedia"><img src='/assets/img/social/yt.svg' alt="youtube" className="youtube" width="50%"/></a>
                            <a href="#"><img src='/assets/img/social/ln.svg' alt="Linkedin" className="" width="50%"/></a>
                            <a href="https://www.crunchbase.com/organization/rixster-pedia"><img src='/assets/img/social/cb.svg' alt="CB" className="" width="50%"/></a>
                            <a href="https://www.f6s.com/rixster-pedia"><img src='/assets/img/social/f6s.svg' alt="F6S" className="" width="50%"/></a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6 footer-links">
                        <h4>Legal</h4>
                        <ul>
      {/* <li><a href="#" onClick={() => handleNavigate('/')}>Home</a></li>
      <li><a href="/#why" onClick={() => handleNavigate('/#why')}>About us</a></li>
      <li><a href="/#about" onClick={() => handleNavigate('/#about')}>Services</a></li> */}
      {/* <li><a href="">Terms of service</a></li> */}
      <li><a href="/privacy_policy">Privacy policy</a></li>
      <li><a href="/terms&conditions">Terms and Conditions</a></li>
    </ul>
                    </div>

                  

                    <div className="col-lg-3 col-6 footer-contact text-center text-md-start">
                        <h4>Contact Us</h4>
                        <p>27 De Fonseka Road,</p>
                        <p>Colombo 00500</p>
                        <p>Sri Lanka.</p>
                        <p className="mt-4"><strong>Phone:</strong> <span>(+94) ( 011) 2682258
                        </span></p>
                        <p><strong>Email:</strong> <span>support@rixsterpedia.com</span></p>
                    </div>

                </div>
            </div>

            <div className="container copyright text-center mt-4">
                <p>© <span>Copyright</span> 2024 <strong className="px-1 sitename"><a href="https://rixsterpedia.com/">RixsterPedia</a></strong> <span>All Rights Reserved</span></p>
               
            </div>

        </footer>
    );
};

export default Footer;
