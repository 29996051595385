import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';
import './blog.css';




const Events = () => {
  return (
    <section id="events" className="events section">
      <div className="container-fluid" data-aos="fade-up" data-aos-delay="100">
        <Swiper
          modules={[Pagination, Autoplay]}
          speed={4000} // Increase speed for smoother, slower continuous scroll
  autoplay={{ delay: 0, disableOnInteraction: false }}
  slidesPerView="auto"
  freeMode={true} // Allows continuous, free-flowing scroll
  freeModeMomentum={false} // Disables momentum for smooth, continuous scrolling
  loop={true}
          pagination={{ el: '.swiper-pagination', type: 'bullets', clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
          }}
        >
          <SwiperSlide className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage: `url('assets/img/blog/1.png')` }}>
            <h3>Maldives</h3>
            {/* <div className="price align-self-start">$99</div> */}
            {/* <p className="description">
            This itinerary covers a range of experiences, from cultural exploration to natural wonders, ensuring a well-rounded Maldivian adventure.
            </p> */}
          </SwiperSlide>

          <SwiperSlide className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage:  `url('assets/img/blog/2.png')` }}>
            <h3>Greece</h3>
            {/* <div className="price align-self-start">$289</div> */}
            {/* <p className="description">
            A journey through Greece, blending ancient history, beautiful islands, and stunning landscapes.
            </p> */}
          </SwiperSlide>

          <SwiperSlide className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage:  `url('assets/img/blog/3.png')` }}>
            <h3>Japan</h3>
            {/* <div className="price align-self-start">$499</div> */}
            {/* <p className="description">
            A journey through Japan, combining traditional culture, modern wonders, and natural beauty.
            </p> */}
          </SwiperSlide>

          <SwiperSlide className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage:  `url('assets/img/blog/dubai.png')` }}>
            <h3>Dubai</h3>
            
            {/* <p className="description">
              Laborum aperiam atque omnis minus omnis est qui assumenda quos. Quis id sit quibusdam. Esse quisquam ducimus officia ipsum ut quibusdam maxime. Non enim perspiciatis.
            </p> */}
          </SwiperSlide>
        
          <SwiperSlide className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage:  `url('assets/img/blog/chicago.png')` }}>
            <h3>Chicago</h3>
            
            {/* <p className="description">
            A journey through Greece, blending ancient history, beautiful islands, and stunning landscapes.
            </p> */}
          </SwiperSlide>
          <SwiperSlide className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage:  `url('assets/img/blog/paris.png')` }}>
            <h3>Paris</h3>
            
            {/* <p className="description">
              Laborum aperiam atque omnis minus omnis est qui assumenda quos. Quis id sit quibusdam. Esse quisquam ducimus officia ipsum ut quibusdam maxime. Non enim perspiciatis.
            </p> */}
          </SwiperSlide>
          <SwiperSlide className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage:  `url('assets/img/blog/london.png')` }}>
            <h3>London</h3>
            
            {/* <p className="description">
              Laborum aperiam atque omnis minus omnis est qui assumenda quos. Quis id sit quibusdam. Esse quisquam ducimus officia ipsum ut quibusdam maxime. Non enim perspiciatis.
            </p> */}
          </SwiperSlide>
        
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default Events;
