import React from 'react';
// import features1 from './assets/img/features-1.jpg';
// import features2 from './assets/img/features-2.jpg';
import './featuredetails.css';
import { TbBackground } from 'react-icons/tb';

const FeaturesDetails = () => {
  return (
    <section id="features-details" className="features-details section">
      <div className="container">
        <div className="row gy-4 justify-content-between features-item">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <img src="assets/img/realtime.svg" className="img-fluid" alt="Feature 1" />
          </div>
          <div className="col-lg-5 d-flex align-items-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content">
            <h3 ><span style={{backgroundColor: '#f688124d'}}>Real-Time Updates</span></h3>

              <p>
              Use the most recent travel notifications, weather information, and local advice to be ahead. Let there be no more last-minute surprises!
              </p>
              {/* <a href="#" className="btn more-btn">Learn More</a> */}
            </div>
          </div>
        </div>
        <div className="row gy-4 justify-content-between features-item">
          <div className="col-lg-5 d-flex align-items-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
            <div className="content">
              <h3><span style={{backgroundColor: '#f688124d'}}>User-Friendly Design</span></h3>
              <p>
              Our intuitive dashboard facilitates simple and enjoyable vacation planning, our intuitive interface makes planning your dream trip simple and fun.
              </p>
              {/* <ul>
                <li><i className="bi bi-easel flex-shrink-0"></i> Et corporis ea eveniet ducimus.</li>
                <li><i className="bi bi-patch-check flex-shrink-0"></i> Exercitationem dolorem sapiente.</li>
                <li><i className="bi bi-brightness-high flex-shrink-0"></i> Veniam quia modi magnam.</li>
              </ul> */}
              {/* <a href="#" className="btn more-btn">Learn More</a> */}
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
            <img src="assets/img/userfriendly.svg" className="img-fluid" alt="Feature 2" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesDetails;
