import React from 'react';
import './endfeaturesection.css';

const MoreFeatures = () => {
  return (
    <section id="more-features" className="more-features section">
      <div className="container">
        <div className="row justify-content-around gy-4">
          <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
            <h3>Personalized Schedules for Every Travel Desire</h3>
            <p>
            We are introducing RixsterPedia, your new travel companion. Our app allows you to personalize every aspect of your trip, whether going on a group trip, a solo adventure, or a family vacation. Enjoy the simple things of:

            </p>

            <div className="row">
              <div className="col-lg-6 icon-box d-flex">
              <img src="assets/img/endf/1.svg" alt="Feature" />
                <div>
                  <h4>AI-Assisted Navigation</h4>
                  <p>AI-Powered Route Planning Allows our intelligent AI to plan the ideal vacation plans for you. We'll figure out the ideal routes, travel durations, and distances for a hassle-free, pleasurable trip, whether you're traveling across a city or the country.</p>
                </div>
              </div>

              <div className="col-lg-6 icon-box d-flex">
              <img src="assets/img/endf/2.svg" alt="Feature" />
                <div>
                  <h4>One-Stop Travel Planner</h4>
                  <p>RixsterPedia Hub Simplify your trip preparation with our all-inclusive platform. To keep everything organized in one location, RixsterPedia compiles information about your accommodation and flights, handles reservations, and provides advice and guides.</p>
                </div>
              </div>

              <div className="col-lg-6 icon-box d-flex">
              <img src="assets/img/endf/3.svg" style={{}} alt="Feature" />
                <div>
                  <h4>Planning a Collaborative Group Is Quick</h4>
                  <p>Simple Group Arrangement Organising with relatives or friends? Group travel is made simple with our real-time collaboration function, which makes it simple to share paths and keep everyone informed. Take advantage of PixsterPedia's simplicity and change your vacation experience right now!</p>
                </div>
              </div>

              <div className="col-lg-6 icon-box d-flex">
              <img src="assets/img/endf/er.svg" style={{}} alt="Feature" />
                <div>
                  <h4>Your Ultimate Travel Companion</h4>
                  <p>Personalize every aspect of your trip with RixsterPedia. Whether it's a solo adventure, family vacation, or group journey, our app offers AI-powered navigation, route planning, and a one-stop hub for all your travel needs. Experience seamless group planning and make every journey memorable with Us.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="features-image col-lg-5 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
            <img src="assets/img/endfea.png" alt="Feature" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MoreFeatures;
