import React, { useState } from 'react';
import useGeoNamesSearch from './geonameapi';
import { useNavigate } from 'react-router-dom'; 
import './tripbuilder.css'; // Import the CSS file
import { FaUser, FaUserFriends, FaUsers, FaBriefcase, FaHome, FaPlus } from 'react-icons/fa';
const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    location: '',
    tripType:'',
    interests: [],
    newInterest: '',
    email: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [emailError, setEmailError] = useState('');
  const { suggestions, fetchLocationSuggestions, setSuggestions } = useGeoNamesSearch();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  const handleLocationChange = (e) => {
    const query = e.target.value;
    setFormData({ ...formData, location: query });
    fetchLocationSuggestions(query);
  };
  const handleSelect = (location) => {
    setFormData({ ...formData, location });
    setSuggestions([]); 
    // setStep(step + 1);  
  };
  const handleTripTypeChange = (type) => {
    setFormData({ ...formData, tripType: type });
  };
  const handleInterestChange = (interest) => {
    setFormData((prevState) => ({
      ...prevState,
      interests: prevState.interests.includes(interest)
        ? prevState.interests.filter(i => i !== interest)
        : [...prevState.interests, interest]
    }));
  };
  const handleNewInterestChange = (e) => {
    setFormData({ ...formData, newInterest: e.target.value });
  };
  const handleAddInterest = () => {
    const { newInterest, interests } = formData;
    if (newInterest && !interests.includes(newInterest)) {
      setFormData({
        ...formData,
        interests: [...interests, newInterest],
        newInterest: ''
      });
    }
  };
  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };
  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };
  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email } = formData;
    if (validateEmail(email)) {
      // Submit form data and email
      console.log('Form submitted:', formData);
      setFormSubmitted(true);
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <h2><b>Step 1: Where do you want to go?</b></h2>
            <input
              type="text"
              value={formData.location}
              onChange={handleLocationChange}
              placeholder="Search by city or town..."
              className="location-search-input"
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSelect(suggestion.name)}
                >
                  <span>{suggestion.name}, {suggestion.country}</span>
                </div>
              ))}
            </div>
            <button onClick={nextStep} disabled={!formData.location}>Next</button>
          </div>
        );
      case 2:
        return (
          <div>
          <h2><b>Step 2: What kind of trip are you planning?</b></h2>
          <div className="trip-options">
            <div
              className={`trip-option ${formData.tripType === 'Solo' ? 'selected' : ''}`}
              onClick={() => handleTripTypeChange('Solo')}
            >
              <FaUser size={50} />
              <span>Solo Trip</span>
            </div>
            <div
              className={`trip-option ${formData.tripType === 'Friends' ? 'selected' : ''}`}
              onClick={() => handleTripTypeChange('Friends')}
            >
              
              <FaUsers size={50} />
              <span>Friends Trip</span>
            </div>
            <div
              className={`trip-option ${formData.tripType === 'Family' ? 'selected' : ''}`}
              onClick={() => handleTripTypeChange('Family')}
            >
              
              <FaHome size={50} />
              <span>Family Trip</span>
            </div>
            <div
              className={`trip-option ${formData.tripType === 'Office' ? 'selected' : ''}`}
              onClick={() => handleTripTypeChange('Office')}
            >
              <FaBriefcase size={50} />
              <span>Office Trip</span>
            </div>
            <div
              className={`trip-option ${formData.tripType === 'Couple' ? 'selected' : ''}`}
              onClick={() => handleTripTypeChange('Couple')}
            >
              <FaUserFriends size={50} />
              <span>Couple Trip</span>
            </div>
          </div>
          <button onClick={prevStep}>Back</button>
          <button onClick={nextStep} disabled={!formData.tripType}>Next</button>
        </div>
      );
      case 3:
        return (
          <div>
            <h2><b>Step 3: Tell us what you’re interested in</b></h2>
            <div className="interests-options">
              {['Must-see Attractions', 'Great Food', 'Adventure Activities', 'Local Culture', 'Relaxation','Historical Landmarks','Shopping Hotspots','Nature & Wildlife','Luxury Experiences','Wellness & Spa Retreats','Art & Museums','Beach Escapes']
                .map((interest, index) => (
                  <div
                    key={index}
                    className={`interest-option ${formData.interests.includes(interest) ? 'selected' : ''}`}
                    onClick={() => handleInterestChange(interest)}
                  >
                    <span>{interest}</span>
                  </div>
                ))}
              <div className="add-interest">
                <input
                  type="text"
                  value={formData.newInterest}
                  onChange={handleNewInterestChange}
                  placeholder="Add new interest..."
                />
                <button onClick={handleAddInterest}><FaPlus size={20} /></button>
              </div>
              {formData.interests.map((interest, index) => (
                <div
                  key={`custom-${index}`}
                  className={`interest-option ${formData.interests.includes(interest) ? 'selected' : ''}`}
                  onClick={() => handleInterestChange(interest)}
                >
                  <span>{interest}</span>
                </div>
              ))}
            </div>
            <button onClick={prevStep}>Back</button>
            <button onClick={() => setShowModal(true)}>Submit</button>
          </div>
        );
      default:
        return null;
    }
  };

  const renderProgressBar = () => {
    const progress = (step / 3) * 100;
    return (
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div
            className="progress-bar-inner"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    );
  };

  return (
    <div className="container1">
      <div className="form-wrapper">
        {renderProgressBar()}
        {renderStep()}
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Enter your email address</h2>
            <input
              type="email"
              value={formData.email}
              onChange={handleEmailChange}
              placeholder="Your email address"
              className="modal-email-input"
            />
            {emailError && <p className="email-error">{emailError}</p>}
            <div className="button-row">
            <button onClick={handleSubmit}>Submit</button>
            <button onClick={() => setShowModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
            {/* Success Message Modal */}
            {formSubmitted && (
        <div className="modal">
          <div className="modal-content">
            <h2><b>Thank You!</b></h2>
            <p>Your plans and wishes have been fed into Rixsterpedia AI! 🎉</p>
            <p>Get ready to receive personalized adventure recommendations straight to your inbox. We can’t wait to help you plan your next unforgettable journey!"</p>
            <div className="button-row">
            <button onClick={() => window.location.href = 'mailto:support@rixsterpedia.com'}>Purchase Full Version</button>
            <button onClick={() => navigate('/')}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
