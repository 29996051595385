import Nav from "../components/header";


import Footer from "../components/footer";

import FlightBookingForm from "../components/newtrip";

export default function Trip(){
    return(
    <>
<Nav/>
<FlightBookingForm/>
<Footer/>
    </>
    );
}