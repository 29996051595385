import React from 'react';
import { useEffect } from 'react';
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { BiPlayCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import './hero.css';

const HeroSection = () => {
    return (
        <section id="hero" className="hero section">
          <div className="hero-bg">
            {/* <img src="assets/img/hero-bg-light.webp" alt="Hero Background" /> */}
          </div>
          <div className="container text-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h2 data-aos="fade-up">Your Perfect Travel Partner!
              </h2>
              <p data-aos="fade-up" data-aos-delay="100">
              Are you bored of looking through travel guides and websites to make vacation plans? With RixsterPedia, say goodbye to the stress of trip planning!
              </p>
              <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                <Link to="/trip" className="btn-get-started">Create a new trip</Link>
                <a href="/tripbuilder" className="btn-watch-video">
                <MdOutlineAddLocationAlt className="icon-style" />
                  <span>AI Trip Builder Demo</span>
                </a>
              </div>
              <img 
                src="assets/img/hero_img.svg" 
                className="img-fluid hero-img" 
                alt="Hero Services" 
                data-aos="zoom-out" 
                data-aos-delay="300"
                
              />
            </div>
          </div>
        </section>
      );
};

export default HeroSection;


