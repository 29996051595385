import React from 'react';
import blogs from '../data/blog';
import Blog from './blog';
import Events from './blog';

export default function BlogSection() {
    return (
        <section id="blog" className="blog section">

            {/* Section Title */}
            <div className="container section-title" data-aos="fade-up">
                <h2>See Our Most Visited Trip Plans<br /></h2>
            </div>
            {/* End Section Title */}
        <Events/>


        </section>
    );
}
