import React, { useState, useEffect } from "react";
import './newtrip.css';
import { FaPlaneDeparture, FaRetweet, FaMapMarkedAlt } from 'react-icons/fa';
const FlightBookingForm = () => {
  const [tripType, setTripType] = useState("roundTrip");
  const [isScrolled, setIsScrolled] = useState(false);
  
  const [fromCityInput, setFromCityInput] = useState("");
  const [fromSuggestions, setFromSuggestions] = useState([]);
  
  const [toCityInput, setToCityInput] = useState("");
  const [toSuggestions, setToSuggestions] = useState([]);
  const YourComponent = () => {
    const handleRedirect = () => {
      window.location.href = '/'; // Redirect to home page
    };
    return (
      <input
        className="search-btn"
        type="button" // Change to "button" to prevent form submission
        value="Create a New Trip"
        onClick={handleRedirect}
      />
    );
  };
  const fetchCitySuggestions = async (inputValue, setSuggestions) => {
    if (inputValue.length > 2) {
        try {
            const response = await fetch(
                `http://api.geonames.org/searchJSON?name_startsWith=${inputValue}&maxRows=10&username=rixsterpedia`
            );
            const data = await response.json();
            
            if (data.geonames) { // Check if geonames exists
                setSuggestions(data.geonames.map(city => city.name));
            } else {
                setSuggestions([]);
            }
        } catch (error) {
            console.error("Error fetching city suggestions:", error);
            setSuggestions([]);
        }
    } else {
        setSuggestions([]);
    }
};

  const handleInputChange = (e, setCityInput, setSuggestions) => {
    const value = e.target.value;
    setCityInput(value);
    fetchCitySuggestions(value, setSuggestions);
  };

  const handleSuggestionClick = (city, setCityInput, setSuggestions) => {
    setCityInput(city);
    setSuggestions([]);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 100); // Adjust this value based on when you want to trigger the change
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <form action="" method="post" className="trip-form">
  <fieldset className="trip-type-group">
      <div
        className={`trip-type-option ${tripType === "oneWay" ? "selected" : ""}`}
        onClick={() => setTripType("oneWay")}
      >
        <FaPlaneDeparture className="icon" />
        <span>One Way</span>
      </div>
      <div
        className={`trip-type-option ${tripType === "roundTrip" ? "selected" : ""}`}
        onClick={() => setTripType("roundTrip")}
      >
        <FaRetweet className="icon" />
        <span>Round Trip</span>
      </div>
      <div
        className={`trip-type-option ${tripType === "multiCity" ? "selected" : ""}`}
        onClick={() => setTripType("multiCity")}
      >
        <FaMapMarkedAlt className="icon" />
        <span>Multi City</span>
      </div>
    </fieldset>


      <fieldset>
        <div className="group-field">
          <label htmlFor="from" className="bold-type">
            From
          </label>
          <input
            className="from-place"
            type="text"
            name="from"
            maxLength="100"
            placeholder="Any worldwide city"
            value={fromCityInput}
            onChange={(e) => handleInputChange(e, setFromCityInput, setFromSuggestions)}
          />
          {fromSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {fromSuggestions.map((city, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(city, setFromCityInput, setFromSuggestions)}
                >
                  {city}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="group-field">
          <label htmlFor="to" className="bold-type">
            To
          </label>
          <input
            className="to-place"
            type="text"
            name="to"
            maxLength="100"
            placeholder="Any worldwide city"
            value={toCityInput}
            onChange={(e) => handleInputChange(e, setToCityInput, setToSuggestions)}
          />
          {toSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {toSuggestions.map((city, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(city, setToCityInput, setToSuggestions)}
                >
                  {city}
                </li>
              ))}
            </ul>
          )}
        </div>
      </fieldset>
      <fieldset className="date-input-fieldset">
  <div className="group-field">
    <label htmlFor="depart" className="bold-type">
      Depart on
    </label>
    <div className="date-input-container">
      <input
        id="depart"
        placeholder="Pick a date"
        type="date"
        name="depart"
        className="date-input"
      />
    </div>
  </div>
  <div className="group-field">
    <label htmlFor="return" className="bold-type">
      Return on
    </label>
    <div className="date-input-container">
      <input
        id="return"
        placeholder="Pick a date"
        type="date"
        name="return"
        className="date-input"
      />
    </div>
  </div>
</fieldset>


      <fieldset>
        <div className="group-select-field">
          <label htmlFor="adults" className="bold-type">
            Adults
          </label>
          <select name="adults" className="ticket-count">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div className="group-select-field">
          <label htmlFor="children">Children</label>
          <select name="children" className="ticket-count">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <label className="label-hint"> 2–11 years</label>
        </div>
        <div className="group-select-field">
          <label htmlFor="infants">Infants</label>
          <select name="infants" className="ticket-count">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <label className="label-hint">Below 2 yrs</label>
        </div>
      </fieldset>

      <fieldset>
        <a className="more-link" href="#">
          
          <b>More options: </b> Travel Modes, Activity Preference
        </a>
      </fieldset>

      <fieldset>
        <div className="group-field">
          <label htmlFor="class-of-travel">Travel Mode</label>
          <select name="class-of-travel" className="travel-class-select">
            <option value="economy">Own Vehicle</option>
            <option value="business">Bus</option>
            <option value="firstClass">Train</option>
            <option value="firstClass">Aeroplane</option>
          </select>
        </div>
        <div className="group-field">
          <label htmlFor="airline-name">Preferred Activities</label>
          <input
            className="airline-name"
            type="text"
            name="airline-name"
            size="30"
            maxLength="100"
            placeholder="Brief description about What you want to do"
          />
        </div>
      </fieldset>
      <hr />

      <YourComponent/>
    </form>
  );
};

export default FlightBookingForm;
