import { useState } from 'react';

const useGeoNamesSearch = () => {
  const [suggestions, setSuggestions] = useState([]);

  const fetchLocationSuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `http://api.geonames.org/searchJSON?name_startsWith=${query}&maxRows=10&username=rixsterpedia`
      );
      const data = await response.json();
      setSuggestions(
        data.geonames.map((place) => ({
          name: place.name,
          country: place.countryName,
        }))
      );
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  };

  return { suggestions, fetchLocationSuggestions, setSuggestions };
};

export default useGeoNamesSearch;
