import React from "react";
import AI from "../components/tripbuilder";
import Nav from "../components/header";
import { FaMapMarkerAlt } from 'react-icons/fa'; // Importing a location icon
const Power = () => {
    return (
      <footer style={footerStyle}>
        <p style={{ fontSize: '12px', color: '#000', margin: 0 }}>
          Powered by{' '}
          <span style={{ fontWeight: 'bold', color: '87A922' }}>
            RixsterPedia AI
          </span>{' '}
          <FaMapMarkerAlt style={{ color: '#f68712', marginLeft: '5px' }} />
        </p>
      </footer>
    );
  };
  
  const footerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    padding: '10px 0',
    backgroundColor: '',
  };
  

  
const TripAI=()=>{
return(
<>

<Nav/>

<AI/>
<Power/>
</>
);
};
export default TripAI;